import * as React from 'react';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Flex,
  ModalOverlay,
  ModalContent,
  useToast,
} from '@chakra-ui/react';
import { updateKnowledgebase } from '../../../../services/knowledgebaseService';

export interface EditNameModalProps {
  knowledgeBaseId: string;
  isOpen: boolean;
  onClose: () => void;
  name: string;
}

export const EditNameModal = ({
  knowledgeBaseId,
  isOpen,
  onClose,
  name,
}: EditNameModalProps) => {
  const [text, setText] = React.useState(name);

  const toast = useToast();

  React.useEffect(() => {
    setText(name);
  }, [isOpen, name]);

  const handleSave = async () => {
    try {
      await updateKnowledgebase(knowledgeBaseId, { name: text });
      onClose();
      toast({
        title: 'Name updated',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error updating name',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={() => onClose()} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Chatbot Name</ModalHeader>
        <ModalBody>
          <Input value={text} onChange={(e) => setText(e.target.value)} />
        </ModalBody>
        <ModalFooter>
          <Flex gap="8px">
            <Button variant="outline" onClick={onClose}>
              Close
            </Button>
            <Button colorScheme="blue" mr={3} onClick={handleSave}>
              Save
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
