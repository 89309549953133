import * as React from 'react';

import { WIDGET_URL } from '../../../../config';
import { API_URL } from '../../../../config';

import { SectionTitle } from '../../../../components/SectionTitle/SectionTitle';
import {
  Flex,
  VStack,
  Heading,
  Text,
  Box,
  HStack,
  Button,
  useToast,
} from '@chakra-ui/react';
import { Knowledgebase } from '../../../../types/knowledgebase.type';

import styles from './AddToSite.module.scss';

interface AddToSiteProps {
  chatBot: Knowledgebase;
}

export const AddToSite = ({ chatBot }: AddToSiteProps) => {
  const toast = useToast();

  const getAddToWebsiteContent = React.useCallback(() => {
    // if (user && user?.subscriptionData?.name === 'FREE') {
    // 	return <VStack
    // 		alignItems="center"
    // 		direction="column"
    // 		justifyContent="center"
    // 		pt={32}
    // 		pb={32}
    // 		spacing="9"
    // 	>
    // 		<NoDataSubscribeIcon width="auto" height="180px" />
    // 		<Box textAlign="center">
    // 			<Heading
    // 				maxW="580px"
    // 				fontSize="xl"
    // 				fontWeight="500"
    // 				as="h3"
    // 				mb="4"
    // 				color="gray.500"
    // 				lineHeight="medium"
    // 				textAlign="center"
    // 			>
    // 				Upgrade to a paid plan to add chatbot to your website
    // 			</Heading>
    // 			<Link to="/app/settings/subscription">
    // 				<Button variant='outline' colorScheme='blue' size='md'>Subscribe Now</Button>
    // 			</Link>
    // 		</Box>
    // 	</VStack>
    // }

    return (
      <VStack spacing={12} alignItems="start">
        <VStack alignItems="start">
          <Heading fontSize="md">Chatbot with Launcher Icon</Heading>
          <Text color="gray.500" fontSize="sm">
            A launcher icon will be displayed at the corner of your website.
            Once clicked, the full chat interface will appear.
          </Text>
          <Box className={styles.codeBlock}>
            <span style={{ color: '#808080' }}>
              &lt;<span style={{ color: '#ed6a43' }}>script</span>
              <span style={{ color: '#0086b3' }}> id</span>=
              <span style={{ color: '#183691' }}>"__arialabs__"</span>
              <span style={{ color: '#0086b3', paddingLeft: '4px' }}>
                {' '}
                data-chatbot-id
              </span>
              =<span style={{ color: '#183691' }}>"{chatBot.id}"</span>
              <br></br>
              <span style={{ color: '#0086b3', paddingLeft: '20px' }}>
                {' '}
                src
              </span>
              =<span style={{ color: '#183691' }}>"{WIDGET_URL}/aria.js"</span>
              <br></br>
              <span style={{ color: '#0086b3', paddingLeft: '20px' }}>
                {' '}
                data-base-url
              </span>
              =<span style={{ color: '#183691' }}>"{API_URL}"</span>
              <br></br>
              <span style={{ color: '#0086b3', paddingLeft: '20px' }}>
                {' '}
                data-widget-url
              </span>
              =<span style={{ color: '#183691' }}>"{WIDGET_URL}"</span>
              &gt;
            </span>
            <br></br>
            <span style={{ color: '#808080' }}>
              &lt;/<span style={{ color: '#ed6a43' }}>script</span>&gt;
            </span>
          </Box>
          <HStack mt="6">
            <Button
              colorScheme="blue"
              variant="solid"
              size="sm"
              onClick={() => {
                navigator.clipboard.writeText(
                  `<script id="__arialabs__" src="${WIDGET_URL}/aria.js" data-chatbot-id="${chatBot.id}" data-base-url="${API_URL}" data-widget-url="${WIDGET_URL}"></script>`,
                );
                toast({
                  title: `Copied to Clipboard`,
                  status: 'info',
                  isClosable: true,
                });
              }}
            >
              Copy code
            </Button>
          </HStack>
        </VStack>
        <VStack alignItems="start">
          <Heading fontSize="md">Embedded Chat Interface (No Launcher)</Heading>
          <Text color="gray.500" fontSize="sm">
            Chat interface is permanently displayed on your web page without a
            launcher icon.
          </Text>
          <Box className={styles.codeBlock}>
            <span style={{ color: '#808080' }}>
              &lt;<span style={{ color: '#ed6a43' }}>iframe</span>
              <span style={{ color: '#0086b3', paddingLeft: '4px' }}>
                {' '}
                width
              </span>
              =<span style={{ color: '#183691' }}>"500px"</span>
              <span style={{ color: '#0086b3', paddingLeft: '4px' }}>
                {' '}
                height
              </span>
              =<span style={{ color: '#183691' }}>"700px"</span>
              <span style={{ color: '#0086b3', paddingLeft: '4px' }}>
                {' '}
                frameborder
              </span>
              =<span style={{ color: '#183691' }}>"0"</span>
              <br></br>
              <span style={{ color: '#0086b3', paddingLeft: '20px' }}>
                {' '}
                src
              </span>
              =
              <span style={{ color: '#183691' }}>
                "{WIDGET_URL}/?kbId={chatBot.id}
                &embed=true&hide-chat-actions=true&baseUrl={API_URL}"
              </span>
              &gt;
            </span>
            <br></br>
            <span style={{ color: '#808080' }}>
              &lt;/<span style={{ color: '#ed6a43' }}>iframe</span>&gt;
            </span>
          </Box>
          <HStack mt="6">
            <Button
              colorScheme="blue"
              variant="solid"
              size="sm"
              onClick={() => {
                navigator.clipboard.writeText(
                  `<iframe width="500px" height="700px" frameborder="0"
								src="${WIDGET_URL}/?kbId=${chatBot.id}&embed=true&hide-chat-actions=true&baseUrl=${API_URL}">
								</iframe>`,
                );
                toast({
                  title: `Copied to Clipboard`,
                  status: 'info',
                  isClosable: true,
                });
              }}
            >
              Copy code
            </Button>
          </HStack>
        </VStack>
        <VStack alignItems="start">
          <Heading fontSize="md">Public Link to Chat Interface</Heading>
          <Text color="gray.500" fontSize="sm">
            Share the chat interface with anyone with the below unique link.
          </Text>
          <Box className={styles.codeBlock}>
            <span style={{ color: '#0086b3' }}>
              "{WIDGET_URL}/?kbId={chatBot.id}
              &embed=true&hide-chat-actions=true&baseUrl={API_URL}"
            </span>
          </Box>
          <HStack mt="6">
            <Button
              colorScheme="blue"
              variant="solid"
              size="sm"
              onClick={() => {
                navigator.clipboard.writeText(
                  `${WIDGET_URL}/?kbId=${chatBot.id}&embed=true&hide-chat-actions=true&baseUrl=${API_URL}`,
                );
                toast({
                  title: `Copied to Clipboard`,
                  status: 'info',
                  isClosable: true,
                });
              }}
            >
              Copy link
            </Button>
          </HStack>
        </VStack>
      </VStack>
    );
  }, [chatBot.id, toast]);

  return (
    <Flex direction="column" h="100%" overflow="auto">
      <SectionTitle
        title="Add to your website"
        description="Choose any of the below options to add the chatbot to your website."
      />
      <Flex direction="column" alignItems="start">
        {getAddToWebsiteContent()}
      </Flex>
    </Flex>
  );
};
