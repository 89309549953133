import * as React from 'react';
import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuDivider,
  Progress,
  MenuItem,
  MenuList,
  Spinner,
  VStack,
  Heading,
  Text,
} from '@chakra-ui/react';

import styles from './App.module.scss';

import { Link, NavLink, Route, Switch } from 'react-router-dom';
import { Logo } from '../../components/Logo/Logo';
import { ChatbotList } from '../ChatbotList/ChatbotList';
import { Settings } from '../Settings/Settings';
import { getUserProfile } from '../../services/userServices';
import { CurrentUser, User } from '../../services/appConfig';
import { DemoChatbots } from '../DemoChatbots/DemoChatbots';
import classNames from 'classnames';
import { formatNumber } from '../../utils/commonUtils';
import { AddFolderIcon } from '../../assets/icons/AddFolder.icon';
import { ShareIcon } from '../../assets/icons/Share.icon';
import { SharedChatbotList } from '../SharedChatbotList/SharedChatbotList';
interface AppProps {
  onLoginOut: () => void;
}

export const App = (props: AppProps) => {
  const [userData, setUserData] = React.useState<User | null>(null);
  React.useEffect(() => {
    async function fetchData() {
      try {
        const response = await getUserProfile();
        CurrentUser.set(response.data);
        setUserData(response.data);
      } catch (error) {
        console.log('Unable to user profile', error);
      } finally {
      }
    }
    fetchData();
    console.log("HEY")
  }, []);

  const getUsageComponent = React.useCallback(() => {
    if (!userData) return null;

    try {
      let usage = (
        (userData.monthUsage.count * 100) /
        userData?.subscriptionData?.maxTokens
      ).toString();

      usage = parseFloat(usage).toFixed(2);

      let isExceded = false;

      if (userData?.subscriptionData?.maxTokens !== undefined) {
        isExceded =
          userData.monthUsage.count >= userData?.subscriptionData?.maxTokens;
      }

      return (
        <Box
          className={classNames(styles.usageCont, {
            [styles.usageContExceeded]: isExceded,
          })}
        >
          <Heading className={styles.usagePlan} size="h4" color="gray.500">
            {(userData?.subscriptionData?.name || '')
              .toLowerCase()
              .replace('app sumo', 'LTD')}
          </Heading>
          <Box className={styles.usgaeNumbers}>
            <Text as="span" fontWeight="bold">
              {formatNumber(userData.monthUsage.count)}
            </Text>{' '}
            / {formatNumber(userData?.subscriptionData?.maxTokens)}
          </Box>
          <Text className={styles.usageLabel} fontSize="sm">
            Monthly usage limits
          </Text>
          <Progress
            className={styles.progressbar}
            w="100%"
            value={parseFloat(usage)}
            size="sm"
            colorScheme={isExceded ? 'red' : 'blue'}
            borderRadius="md"
          />
          {userData?.subscriptionData?.type !== 'LIFETIME' ? (
            <Box w="100%" className={styles.usageUpgradeBtn}>
              <Link to="/app/settings/subscription/">
                <Button w="100%" colorScheme="gray" variant="solid" size="sm">
                  Upgrade now
                </Button>
              </Link>
            </Box>
          ) : null}
        </Box>
      );
    } catch (error) {
      console.log('error', error);
      return null;
    }
  }, [userData]);
  return (
    <VStack spacing="0" w="100%" h="100vh">
      <Flex
        shrink={0}
        shadow="base"
        w="100%"
        h="60px"
        bg="white"
        justifyContent="space-between"
        pr="10"
        pb="4"
        pt="4"
        pl="10"
        zIndex="2"
        alignItems="center"
      >
        <Logo></Logo>
        <Box>
          <Menu>
            <MenuButton>
              <Avatar size="sm" bg="teal.500" />
            </MenuButton>
            <MenuList minW="180px">
              <MenuItem fontSize="small">
                <Avatar mr={2} size="sm" bg="teal.500" /> {userData?.email}
              </MenuItem>
              <MenuDivider />
              {/* <Link to="/app/settings/subscription/">
								<MenuItem fontSize="small">Subscription</MenuItem>
							</Link> */}
              <Link to="/app/settings/general/">
                <MenuItem fontSize="small">Settings</MenuItem>
              </Link>
              <MenuDivider />
              <MenuItem onClick={props.onLoginOut} fontSize="small">
                Log Out
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </Flex>
      <Flex flex={1} h="calc(100% - 100px)" w="100%">
        <HStack spacing="0" w="100%" justify="start">
          <Flex
            h="100%"
            w="260px"
            shadow="base"
            bg="white"
            flexShrink={0}
            p="10"
            direction="column"
            justifyContent="space-between"
            pt="10"
          >
            <List spacing={7}>
              <ListItem display="flex" alignItems="center" fontSize="sm">
                <NavLink activeClassName={styles.activeNav} to="/app/chat-bots">
                  <Flex alignItems="center">
                    <AddFolderIcon />
                    Projects
                  </Flex>
                </NavLink>
              </ListItem>
              <ListItem display="flex" alignItems="center" fontSize="sm">
                <NavLink
                  activeClassName={styles.activeNav}
                  to="/app/shared-chat-bots"
                >
                  <Flex alignItems="center">
                    <ShareIcon />
                    Shared with me
                  </Flex>
                </NavLink>
              </ListItem>
            </List>
            {getUsageComponent()}
          </Flex>
          {userData ? (
            <Switch>
              <Route path="/app/chat-bots">
                <ChatbotList />
              </Route>
              <Route path="/app/shared-chat-bots">
                <SharedChatbotList />
              </Route>
              <Route path="/app/chat-bots-demo">
                <DemoChatbots />
              </Route>
              <Route path="/app/settings">
                <Settings />
              </Route>
              <ChatbotList />
            </Switch>
          ) : (
            <Flex className={styles.appLoading}>
              <Spinner mr={2} /> Loading App...
            </Flex>
          )}
        </HStack>
      </Flex>
    </VStack>
  );
};
