import * as React from 'react';
import {
  Box,
  Flex,
  Heading,
  useToast,
  VStack,
  Input,
  Button,
} from '@chakra-ui/react';
import { Link, useHistory } from 'react-router-dom';
import { createKnowledgebase } from '../../services/knowledgebaseService';

export const CreateChatBots = () => {
  const [name, setName] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const toast = useToast();
  const history = useHistory();

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);

      const { data: chatBot } = await createKnowledgebase({
        name,
      });

      setIsSubmitting(false);
      toast({
        title: `Successfully created your chatbot`,
        status: 'success',
        isClosable: true,
      });
      history.push(`/app/edit-chatbot/${chatBot.id}/?step=chatbot`);
    } catch (err) {
      setIsSubmitting(false);
      toast({
        title: `Failed to create chatbot`,
        description: err.message,
        status: 'error',
        isClosable: true,
      });
    }
  };

  return (
    <VStack w="100%" h="100vh" overflow="hidden">
      <Flex
        shrink={0}
        alignItems="center"
        w="100%"
        justifyContent="start"
        h="60px"
        bg="blue.500"
        color="white"
        pl="6"
      >
        <Link to="/app/chat-bots/">
          <Flex alignItems="center" justifyContent="space-between">
            <Flex>
              <svg
                viewBox="0 0 24 24"
                width="24"
                height="24"
                stroke="currentColor"
                stroke-width="2"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="css-i6dzq1"
              >
                <line x1="19" y1="12" x2="5" y2="12"></line>
                <polyline points="12 19 5 12 12 5"></polyline>
              </svg>
              <Heading ml="2" color="white" fontSize="18" fontWeight="400">
                Chat bots
              </Heading>
            </Flex>
            <Flex></Flex>
          </Flex>
        </Link>
      </Flex>
      <Flex flex={1} h="calc(100% - 100px)" w="100%">
        <Box
          w="100%"
          maxW="1320px"
          p="8"
          bg="white"
          shadow="sm"
          h="100%"
          borderRadius="lg"
          position="relative"
        >
          <Heading fontSize="24" mb="6">
            Create Chatbot
          </Heading>
          <Input
            placeholder="Name"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
          <Flex justifyContent="flex-end">
            <Button
              colorScheme="blue"
              mt="4"
              onClick={handleSubmit}
              isLoading={isSubmitting}
            >
              Create Chatbot
            </Button>
          </Flex>
        </Box>
      </Flex>
    </VStack>
  );
};
