import * as React from 'react';

import { SectionTitle } from '../../../../components/SectionTitle/SectionTitle';
import { Flex } from '@chakra-ui/react';
import {
  Knowledgebase,
  OfflineMessagePagination,
} from '../../../../types/knowledgebase.type';

import styles from './OfflineMessages.module.scss';
import { OfflineMessagesNew } from '../../../OfflineMessages/OfflineMessagesNew';
import { getOfflineMessages } from '../../../../services/knowledgebaseService';

interface OfflineMessagesProps {
  chatBot: Knowledgebase;
}

export const OfflineMessages = ({ chatBot }: OfflineMessagesProps) => {
  const [offlineMessages, setOfflineMessages] =
    React.useState<OfflineMessagePagination>();
  const [isChatLoading, setIsChatLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    async function fetchData() {
      try {
        const response = await getOfflineMessages(chatBot.id, '1');
        setOfflineMessages(response.data);
      } catch (error) {
        console.log('Unable to fetch OfflineMessages', error);
      } finally {
      }
    }
    fetchData();
  }, [chatBot.id]);

  const handleOfflinePageClick = React.useCallback(
    async (selectedPage: number) => {
      try {
        setIsChatLoading(true);
        const response = await getOfflineMessages(
          chatBot.id,
          (selectedPage + 1).toString(),
        );
        setOfflineMessages(response.data);
      } catch (error) {
        console.log('Unable to fetch offlineMeesages', error);
      } finally {
        setIsChatLoading(false);
      }
    },
    [chatBot.id],
  );

  return (
    <Flex direction="column" alignItems="center" h="100%" overflow="auto">
      <SectionTitle
        title="Offline messages"
        description="Offline messages sent by your customers."
      />
      <Flex w="100%" className={styles.trainingDataCont}>
        {offlineMessages && offlineMessages.results && (
          <OfflineMessagesNew
            isChatListLoading={isChatLoading}
            onPageChange={handleOfflinePageClick}
            chatSessionsPage={offlineMessages}
          />
        )}
      </Flex>
    </Flex>
  );
};
