import * as React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Flex,
  Input,
  IconButton,
  Tag,
  TagCloseButton,
  useToast,
} from '@chakra-ui/react';

import {
  getTeamById,
  deleteTeam,
  createTeam,
  addCollaborator,
  deleteCollaborator,
} from '../../../../../../services/teamServices';
import { FaPlus } from 'react-icons/fa';

interface TeamModalProps {
  title: string;
  open: boolean;
  onClose: () => void;

  knowledgeBaseId: string;
  teamId?: string;
}

const TeamModal = ({
  knowledgeBaseId,
  teamId,
  title,
  open,
  onClose,
}: TeamModalProps) => {
  const [name, setName] = React.useState<string>('');
  const [collaboratorInput, setCollaboratorInput] = React.useState<string>('');
  const [collaborators, setCollaborators] = React.useState<string[]>([]);

  const toast = useToast();

  const fetchTeam = React.useCallback(async () => {
    const response = await getTeamById(teamId ?? '');

    setName(response.data.name);
    setCollaborators(response.data.collaborators?.map((c) => c.email) ?? []);
  }, [teamId]);

  React.useEffect(() => {
    if (open && teamId) {
      fetchTeam();
    }
  }, [fetchTeam, teamId, open]);

  React.useEffect(() => {
    if (!open) {
      setName('');
      setCollaboratorInput('');
      setCollaborators([]);
    }
  }, [open]);

  const handleDelete = async () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Are you sure you want to delete this team?')) {
      try {
        await deleteTeam(teamId ?? '');
        onClose();
      } catch (error) {
        toast({
          title: 'Error',
          description:
            error?.response?.data?.message ||
            "Couldn't delete team. Please try again.",
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const handleSave = async () => {
    await createTeam(knowledgeBaseId, name);
    onClose();
  };

  const handleAddCollaborator = async () => {
    try {
      await addCollaborator(teamId ?? '', collaboratorInput);
      fetchTeam();
      setCollaboratorInput('');
    } catch (error) {
      toast({
        title: 'Error',
        description:
          error?.response?.data?.message ||
          "Couldn't add collaborator. Please try again.",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDeleteCollaborator = async (collaborator: string) => {
    await deleteCollaborator(teamId ?? '', collaborator);
    fetchTeam();
  };

  return (
    <Modal isOpen={open} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>

        <ModalBody>
          <Flex direction="column" gap="16px">
            <Input
              placeholder="Team Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              disabled={!!teamId}
            />
            {teamId && (
              <>
                <Flex gap="8px">
                  <Input
                    placeholder="Add Collaborator Email"
                    value={collaboratorInput}
                    onChange={(e) => setCollaboratorInput(e.target.value)}
                  />
                  <IconButton
                    aria-label="Add Collaborator"
                    icon={<FaPlus />}
                    onClick={handleAddCollaborator}
                  />
                </Flex>

                <Flex flexWrap="wrap" gap="8px">
                  {collaborators.map((collaborator, index) => (
                    <Tag
                      key={`collaborator-${index}`}
                      variant="solid"
                      colorScheme="blue"
                      size="md"
                    >
                      {collaborator}
                      <TagCloseButton
                        onClick={() => handleDeleteCollaborator(collaborator)}
                      />
                    </Tag>
                  ))}
                </Flex>
              </>
            )}
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Flex justifyContent="space-between" width="100%">
            <Flex>
              {teamId && (
                <Button
                  variant="outline"
                  colorScheme="red"
                  mr={3}
                  onClick={handleDelete}
                >
                  Delete
                </Button>
              )}
            </Flex>
            <Flex>
              <Button variant="outline" onClick={onClose}>
                Close
              </Button>
              {!teamId && (
                <Button colorScheme="blue" ml="3" onClick={handleSave}>
                  Save
                </Button>
              )}
            </Flex>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default TeamModal;
