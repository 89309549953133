import * as React from 'react';

import { SectionTitle } from '../../../../components/SectionTitle/SectionTitle';
import {
  Flex,
  HStack,
  Box,
  Alert,
  Text,
  AlertTitle,
  AlertDescription,
  Heading,
} from '@chakra-ui/react';
import { ChatBot } from '../../../../components/ChatBot/ChatBot';
import { Knowledgebase } from '../../../../types/knowledgebase.type';

import styles from './DataSources.module.scss';
import { Steps } from '../../EditChatbot';

interface DataSourcesProps {
  chatBot: Knowledgebase;
  goToStep: (step: Steps) => void;
}

export const DataSources = ({ chatBot, goToStep }: DataSourcesProps) => {
  return (
    <Flex direction="column" alignItems="center" h="100%" overflow="auto">
      <SectionTitle
        title="Try out chatbot"
        description="Chat with your chatbot and see how it responds. If you don't get the desired response, follow the instructions below."
      />
      <HStack w="100%">
        <Box w="50%">
          <ChatBot
            knowledgeBaseId={chatBot.id}
            customStyle={chatBot.chatWidgetData}
          />
        </Box>
        <Box w="50%" pos="relative" h="100%">
          <Flex className={styles.resultTips} direction="column">
            <Alert status="info" flexShrink={0} borderRadius="md">
              <Text as="span" color="blue.500" mt="2px" mr="4">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                    stroke="currentcolor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Text>
              <Box>
                <AlertTitle fontWeight="600">
                  Not getting correct response?
                </AlertTitle>
                <AlertDescription fontSize="14px" color="#004c77">
                  It is all about the training data. Here are some ways to
                  improve the accuracy of your chatbot.
                </AlertDescription>
              </Box>
            </Alert>
            <Box className={styles.resultTip}>
              <Box className={styles.resultTipNumber}> 1</Box>
              <Heading className={styles.resultTipHeading} fontSize="medium">
                Add more training Data
              </Heading>
              <Text className={styles.resultTipDescg}>
                The more data you add, the better the chatbot response will be.
                You can add custom data to train in the form of question-answer
                pairs from the{' '}
                <Text
                  as="span"
                  cursor="pointer"
                  textDecoration="underline"
                  onClick={() => {
                    goToStep('train-custom-data');
                  }}
                >
                  Train custom data
                </Text>{' '}
                section{' '}
              </Text>
            </Box>
            <Box className={styles.resultTip}>
              <Box className={styles.resultTipNumber}> 2</Box>
              <Heading className={styles.resultTipHeading} fontSize="medium">
                Ensure Correct Crawling of Website Pages.
              </Heading>
              <Text className={styles.resultTipDescg}>
                verify that all the necessary pages on your website are crawled
                correctly. To do this, head to the{' '}
                <Text
                  as="span"
                  cursor="pointer"
                  textDecoration="underline"
                  onClick={() => {
                    goToStep('product-setup');
                  }}
                >
                  Data sources
                </Text>{' '}
                tab and click on the "View Crawled Data" button.{' '}
              </Text>
            </Box>
            <Box className={styles.resultTip}>
              <Box className={styles.resultTipNumber}> 3</Box>
              <Heading className={styles.resultTipHeading} fontSize="medium">
                Train Chatbot with Previous Support Questions
              </Heading>
              <Text className={styles.resultTipDescg}>
                To reduce the number of repetitive emails and inquiries, make
                sure to train your chatbot with past support questions and
                answers.{' '}
              </Text>
            </Box>
          </Flex>
        </Box>
      </HStack>
    </Flex>
  );
};
