import * as React from 'react';

import { SectionTitle } from '../../../../components/SectionTitle/SectionTitle';
import {
  Flex,
  Box,
  Text,
  Heading,
  VStack,
  Spinner,
  IconButton,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  useToast,
} from '@chakra-ui/react';
import {
  Knowledgebase,
  TrainingData,
  CustomDataPagination,
} from '../../../../types/knowledgebase.type';

import styles from './TrainCustomData.module.scss';
import { Steps } from '../../EditChatbot';
import { AddTrainingData } from '../../../AddTrainingData/AddTrainingData';
import {
  getTrainingData,
  getTrainingDataDetails,
  deleteTrainingData,
} from '../../../../services/knowledgebaseService';
import { NoDataFineTuneIcon } from '../../../../components/Icons/noData/NoDataFineTuneIcon';

import classNames from 'classnames';
import { Paginator } from '../../../../widgets/Paginator/Paginator';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { AddTrainingDataForm } from '../../../AddTrainingDataForm/AddTrainingDataForm';

interface TrainCustomDataProps {
  chatBot: Knowledgebase;
  teamId: string;
}

export const TrainCustomData = ({ chatBot, teamId }: TrainCustomDataProps) => {
  const [customTrainingDataPage, setCustomTrainingDataPage] =
    React.useState<CustomDataPagination>({
      pages: 0,
      results: [],
    });
  const [isCustomDataDetailsLoading, setIsCustomDataDetailsLoading] =
    React.useState(false);
  const [selectedTrainingData, setSelectedTrainingData] =
    React.useState<TrainingData>({} as TrainingData);
  const [isCustomDataLoading, setIsCustomDataLoading] =
    React.useState<boolean>(false);
  const [deleteCustomDataLoading, setDeleteCustomDataLoading] =
    React.useState(false);
  const [questionsToDelete, setQuestionsToDelete] = React.useState<string>('0');

  const cancelRef = React.useRef<any>(null);

  const toast = useToast();

  const {
    isOpen: isDeleteDialogOpen,
    onOpen: onDeleteDialogOpen,
    onClose: onDeleteDialogClose,
  } = useDisclosure();

  React.useEffect(() => {
    async function fetchData() {
      try {
        const response = await getTrainingData(chatBot.id, '1', teamId);

        console.log('response.data.results', response.data.results);
        setCustomTrainingDataPage(response.data);
        if (response.data.results.length > 0) {
          setIsCustomDataDetailsLoading(true);
          const _trainingData = await getTrainingDataDetails(
            chatBot.id,
            response.data.results[0]?.id,
          );
          setSelectedTrainingData(_trainingData.data);
          setIsCustomDataDetailsLoading(false);
        }
      } catch (error) {
        console.log('Unable to fetch custom training data', error);
      } finally {
      }
    }
    fetchData();
  }, [chatBot.id]);

  const handleCustomDataPageChange = React.useCallback(
    async (selectedPage: number) => {
      try {
        setIsCustomDataLoading(true);
        const response = await getTrainingData(
          chatBot.id,
          (selectedPage + 1).toString(),
          teamId,
        );
        setCustomTrainingDataPage(response.data);

        if (response.data.results.length > 0) {
          setIsCustomDataDetailsLoading(true);
          const _trainingData = await getTrainingDataDetails(
            chatBot.id,
            response.data.results[0]?.id,
          );
          setSelectedTrainingData(_trainingData.data);
          setIsCustomDataDetailsLoading(false);
        }
      } catch (error) {
        console.log('Unable to fetch custom training data', error);
      } finally {
        setIsCustomDataLoading(false);
      }
    },
    [chatBot.id, teamId],
  );

  const handleTrainingDataUpdate = React.useCallback(
    (values) => {
      const trainingDataToUpdate = customTrainingDataPage.results.find(
        (data) => data.id === values.id,
      );
      console.log('trainingDataToUpdate', trainingDataToUpdate);
      console.log('values', values);
      if (trainingDataToUpdate) {
        trainingDataToUpdate.title = values.q;
        trainingDataToUpdate.content = values.a;

        setCustomTrainingDataPage({ ...customTrainingDataPage });
      }
    },
    [customTrainingDataPage],
  );

  const handleTrainingDataSave = React.useCallback((values) => {
    console.log('values', values);

    setCustomTrainingDataPage((prevPage) => ({
      ...prevPage,
      results: [values, ...prevPage.results],
    }));
  }, []);

  const handleQuestionDelete = React.useCallback(async () => {
    const id = questionsToDelete;
    setDeleteCustomDataLoading(true);
    try {
      await deleteTrainingData(chatBot.id, id as string);
      const updatedResults = customTrainingDataPage?.results.filter(
        (data) => data.id !== id,
      );
      setCustomTrainingDataPage((prevPage) => ({
        ...prevPage,
        results: updatedResults,
      }));

      toast({
        title: `Custom data has been deleted successfully `,
        status: 'success',
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: `Oops! unable to delte custom data`,
        status: 'error',
        isClosable: true,
      });
    } finally {
      setDeleteCustomDataLoading(false);
      setQuestionsToDelete('0');
      onDeleteDialogClose();
    }
  }, [
    onDeleteDialogClose,
    questionsToDelete,
    toast,
    customTrainingDataPage,
    chatBot.id,
  ]);

  const getCustomDataComponent = React.useCallback(() => {
    if (!customTrainingDataPage?.results.length) {
      return (
        <VStack
          alignItems="center"
          direction="column"
          justifyContent="center"
          w="100%"
          h="100%"
          pt={32}
          pb={32}
          spacing="9"
        >
          <NoDataFineTuneIcon width="auto" height="250px" />
          <Box textAlign="center">
            <Heading
              maxW="580px"
              fontSize="xl"
              fontWeight="500"
              as="h3"
              mb="4"
              color="gray.500"
              lineHeight="medium"
              textAlign="center"
            >
              The more data you add, the better your chatbot response will be.
            </Heading>
          </Box>
        </VStack>
      );
    }
    return (
      <>
        <Box w="450px" className={styles.questionCont} pos="relative">
          {isCustomDataLoading && (
            <Flex
              pos="absolute"
              align="center"
              justify="center"
              top={0}
              bottom={0}
              right={0}
              left={0}
              bg="whiteAlpha.700"
            >
              <Spinner />
            </Flex>
          )}

          <Flex
            direction="column"
            h="calc(100% - 47px)"
            overflowY="auto"
            overflowX="hidden"
          >
            {customTrainingDataPage?.results.map((data) => (
              <Box
                borderBottom="1px"
                borderBottomColor="gray.100"
                bg={selectedTrainingData.id === data.id ? 'gray.100' : 'white'}
                borderRight={selectedTrainingData.id === data.id ? '2px' : '0'}
                borderRightColor="blue.500"
                pr="40px !important"
                className={classNames(styles.questionTab, {
                  [styles.activeQuestion]:
                    selectedTrainingData && selectedTrainingData.id === data.id,
                })}
                key={data.id}
                p="3"
              >
                <Box
                  onClick={async () => {
                    try {
                      setIsCustomDataDetailsLoading(true);
                      const _trainingData = await getTrainingDataDetails(
                        chatBot.id,
                        data.id,
                      );
                      setSelectedTrainingData(_trainingData.data);
                      setIsCustomDataDetailsLoading(false);
                    } catch (error) {
                      console.log('error', error);
                    } finally {
                      setIsCustomDataDetailsLoading(false);
                    }

                    console.log('data', data);
                  }}
                >
                  <Text fontSize="sm" noOfLines={2} fontWeight="medium">
                    {data.title}
                  </Text>
                </Box>
                <IconButton
                  className={styles.questionDeleteBtn}
                  variant="outline"
                  colorScheme="gray"
                  aria-label="Call Sage"
                  fontSize="14px"
                  size="xs"
                  isLoading={
                    deleteCustomDataLoading && questionsToDelete === data.id
                  }
                  onClick={() => {
                    setQuestionsToDelete(data.id);
                    onDeleteDialogOpen();
                  }}
                  icon={<RiDeleteBin5Line />}
                />
              </Box>
            ))}
          </Flex>

          <Box
            bg="white"
            borderTop="1px"
            borderRight="1px"
            borderColor="gray.200"
            justifyContent="center"
          >
            <Paginator
              onPageChange={handleCustomDataPageChange}
              pageRangeDisplayed={5}
              pageCount={customTrainingDataPage.pages}
            />
          </Box>
        </Box>
        <Box
          w="calc(100% - 450px)"
          overflowY="auto"
          className={styles.answerCont}
        >
          {isCustomDataDetailsLoading && (
            <Box className={styles.customDataloadingCont}>
              {' '}
              <Spinner />
            </Box>
          )}
          {selectedTrainingData && (
            <AddTrainingDataForm
              knowledgeBaseId={chatBot.id}
              onSubmit={handleTrainingDataUpdate}
              selectedTrainingData={selectedTrainingData}
            />
          )}
        </Box>
      </>
    );
  }, [
    customTrainingDataPage?.results,
    customTrainingDataPage.pages,
    isCustomDataLoading,
    handleCustomDataPageChange,
    isCustomDataDetailsLoading,
    selectedTrainingData,
    chatBot.id,
    handleTrainingDataUpdate,
    deleteCustomDataLoading,
    questionsToDelete,
    onDeleteDialogOpen,
  ]);

  return (
    <Flex direction="column" alignItems="center" h="100%" overflow="auto">
      <VStack alignItems="start" w="100%" position="relative">
        <SectionTitle
          title="Fine tune data"
          description="Improve your chatbot by adding more Q&A training data. The more you add, the better its responses. Group logically & avoid lengthy inputs."
        />
        <Box position="absolute" right="0" bottom="8">
          <AddTrainingData
            knowledgeBaseId={chatBot.id}
            onSubmit={handleTrainingDataSave}
            initialAnswer=""
            initialQuestion=""
            teamId={teamId}
          />
        </Box>
      </VStack>
      <Flex w="100%" className={styles.trainingDataCont}>
        {getCustomDataComponent()}
      </Flex>
      <AlertDialog
        isOpen={isDeleteDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={onDeleteDialogClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete CustomData
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button size="sm" ref={cancelRef} onClick={onDeleteDialogClose}>
                Cancel
              </Button>
              <Button
                size="sm"
                colorScheme="red"
                onClick={() => {
                  handleQuestionDelete();
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
};
