import axios, { AxiosResponse } from 'axios';
import { baseURL } from '../config';

export interface Collaborator {
  email: string;
}

export interface Team {
  id: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  knowledgeBaseId: string;
  collaborators?: Collaborator[];
}

export async function getTeams(
  knowledgeBaseId: string,
): Promise<AxiosResponse<Team[]>> {
  return await axios({
    baseURL: baseURL,
    method: 'get',
    url: `team/list/${knowledgeBaseId}`,
  });
}

export async function getTeamById(
  teamId: string,
): Promise<AxiosResponse<Team>> {
  return await axios({
    baseURL: baseURL,
    method: 'get',
    url: `team/${teamId}`,
  });
}

export async function deleteTeam(teamId: string): Promise<AxiosResponse<void>> {
  return await axios({
    baseURL: baseURL,
    method: 'delete',
    url: `team/${teamId}`,
  });
}

export async function createTeam(
  knowledgeBaseId: string,
  name: string,
): Promise<AxiosResponse<void>> {
  return await axios({
    baseURL: baseURL,
    method: 'post',
    url: `team/${knowledgeBaseId}`,
    data: {
      name,
    },
  });
}

export async function addCollaborator(
  teamId: string,
  email: string,
): Promise<AxiosResponse<void>> {
  return await axios({
    baseURL: baseURL,
    method: 'post',
    url: `team/${teamId}/collaborator`,
    data: {
      email,
    },
  });
}

export async function deleteCollaborator(
  teamId: string,
  email: string,
): Promise<AxiosResponse<void>> {
  return await axios({
    baseURL: baseURL,
    method: 'delete',
    url: `team/${teamId}/collaborator/${email}`,
  });
}
