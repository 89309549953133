import React, { HTMLAttributes } from 'react';
import logo from './logo-arialabs.png';

interface IconProps extends HTMLAttributes<HTMLImageElement> {
	width?: number;
	height?: number;
}

export const Logo = ({  height = 28, width = 120, ...restProps }: IconProps) => {
	return (
    <img {...restProps} width={width} height={height} src={logo} alt="Logo AriaLabs" />
	);
};
