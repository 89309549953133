import * as React from 'react';

import { SectionTitle } from '../../../../components/SectionTitle/SectionTitle';
import { Flex } from '@chakra-ui/react';
import {
  Knowledgebase,
  ChatSessionPagination,
} from '../../../../types/knowledgebase.type';

import styles from './ChatSessions.module.scss';
import { getChatSessions } from '../../../../services/knowledgebaseService';
import { ChatSessionsNew } from '../../../ChatSessions/ChatSessionsNew';

interface ChatSessionsProps {
  chatBot: Knowledgebase;
}

export const ChatSessions = ({ chatBot }: ChatSessionsProps) => {
  const [chatSessions, setChatSessions] =
    React.useState<ChatSessionPagination>();
  const [isChatLoading, setIsChatLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    async function fetchData() {
      try {
        const response = await getChatSessions(chatBot.id, '1');
        setChatSessions(response.data);
      } catch (error) {
        console.log('Unable to fetch chatSessions', error);
      } finally {
      }
    }
    fetchData();
  }, [chatBot.id]);

  const handlePageClick = React.useCallback(
    async (selectedPage: number) => {
      try {
        setIsChatLoading(true);
        const response = await getChatSessions(
          chatBot.id,
          (selectedPage + 1).toString(),
        );
        setChatSessions(response.data);
      } catch (error) {
        console.log('Unable to fetch chatSessions', error);
      } finally {
        setIsChatLoading(false);
      }
    },
    [chatBot.id],
  );

  return (
    <Flex direction="column" alignItems="center" h="100%" overflow="auto">
      <SectionTitle
        title="Chat sessions"
        description="All the chat sessions with your customers."
      />
      <Flex w="100%" className={styles.trainingDataCont}>
        {chatSessions && (
          <ChatSessionsNew
            isChatListLoading={isChatLoading}
            onPageChange={handlePageClick}
            chatSessionsPage={chatSessions}
          />
        )}
      </Flex>
    </Flex>
  );
};
