import * as React from 'react';
import Pagination from 'react-paginate';
import styles from './Paginator.module.scss';

const ReactPaginate = Pagination as any;
interface PaginatorProps {
  onPageChange: (page: number) => void;
  pageRangeDisplayed: number;
  pageCount: number;
}

export const Paginator = ({
  onPageChange,
  pageRangeDisplayed,
  pageCount,
}: PaginatorProps) => {
  const handlePageChange = React.useCallback(
    (selectedItem: { selected: number }) => {
      onPageChange(selectedItem.selected);
    },
    [onPageChange],
  );

  return (
    <ReactPaginate
      className={styles.pagination}
      breakLabel="..."
      nextLabel="next"
      containerClassName={styles.container}
      previousClassName={styles.previous}
      nextClassName={styles.next}
      pageClassName={styles.page}
      activeClassName={styles.active}
      breakClassName={styles.break}
      onPageChange={handlePageChange}
      pageRangeDisplayed={pageRangeDisplayed}
      pageCount={pageCount}
      previousLabel="previous"
      renderOnZeroPageCount={null}
    />
  );
};
