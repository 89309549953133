import * as React from 'react';
import {
  Flex,
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  IconButton,
  Button,
} from '@chakra-ui/react';
import { FaEdit } from 'react-icons/fa';

import { withRouter, RouteComponentProps } from 'react-router-dom';
import { getTeams, Team } from '../../../../services/teamServices';
import TeamModal from './components/TeamModal/TeamModal';
import { SectionTitle } from '../../../../components/SectionTitle/SectionTitle';

interface TeamsProps extends RouteComponentProps {
  knowledgeBaseId: string;
}

const Teams = ({ knowledgeBaseId }: TeamsProps) => {
  const [teams, setTeams] = React.useState<Team[]>([]);
  const [openAddTeamModal, setOpenAddTeamModal] =
    React.useState<boolean>(false);
  const [openEditTeamModal, setOpenEditTeamModal] =
    React.useState<boolean>(false);
  const [selectedTeam, setSelectedTeam] = React.useState<Team | null>(null);

  const fetchTeams = React.useCallback(async () => {
    const response = await getTeams(knowledgeBaseId);

    setTeams(response.data);
  }, [knowledgeBaseId]);

  React.useEffect(() => {
    fetchTeams();
  }, [fetchTeams]);

  const handleCloseModal = () => {
    setOpenAddTeamModal(false);
    setOpenEditTeamModal(false);
    fetchTeams();
  };

  return (
    <Flex direction="column" h="100%" overflow="auto">
      <SectionTitle
        title="Edit your teams"
        description="Create and manage your teams. Assign collaborators to each team."
      />
      <Flex direction="column" alignItems="start">
        <Flex width="100%" direction="column" gap="16px">
          <Flex justifyContent="flex-end">
            <Button
              colorScheme="blue"
              onClick={() => setOpenAddTeamModal(true)}
            >
              Add Team
            </Button>
          </Flex>
          <TableContainer width="100%">
            <Table variant="simple">
              <TableCaption>Teams</TableCaption>
              <Thead>
                <Tr>
                  <Th width="90%">Name</Th>
                  <Th>Edit</Th>
                </Tr>
              </Thead>
              <Tbody>
                {teams.map((team, index) => (
                  <Tr key={`team-${index}`}>
                    <Td>{team.name}</Td>
                    <Td>
                      <IconButton
                        colorScheme="blue"
                        aria-label="Edit"
                        icon={<FaEdit />}
                        onClick={() => {
                          setSelectedTeam(team);
                          setOpenEditTeamModal(true);
                        }}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>

          <TeamModal
            title="Add Team"
            open={openAddTeamModal}
            onClose={handleCloseModal}
            knowledgeBaseId={knowledgeBaseId}
          />
          <TeamModal
            title="Edit Team"
            open={openEditTeamModal}
            onClose={handleCloseModal}
            teamId={selectedTeam?.id ?? ''}
            knowledgeBaseId={knowledgeBaseId}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
export default withRouter(Teams);
