import { SubscriptionTier } from '../types/subscription.type';

export interface User {
  email: string;
  id: number;
  activeSubscription: string;
  customKeys: {
    useOwnKey: boolean;
  };
  subscriptionData: {
    maxChatbots: number;
    maxChunksPerPage: number;
    maxPages: number;
    maxTokens: number;
    name: string;
    type: SubscriptionTier;
  };
  monthUsage: {
    count: number;
    month: string;
  };
}

export const CurrentUser: any = {
  currentUser: {},
  set(user: User) {
    this.currentUser = user;
  },
  get() {
    return this.currentUser;
  },
};
