import * as React from 'react';
import {
  Box,
  Flex,
  Heading,
  SimpleGrid,
  Spinner,
  VStack,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { useEffect } from 'react';
import { MediaListItem } from '../../components/MediaListItem/MediaListItem';
import { fetchKnowledgebasesSharedWithMe } from '../../services/knowledgebaseService';

export const SharedChatbotList = () => {
  const [chatbotsList, setChatBotList] = React.useState<any>();
  const [chatbotsLoaded, setChatbotsLoaded] = React.useState<boolean>(false);

  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetchKnowledgebasesSharedWithMe();
        setChatbotsLoaded(true);
        setChatBotList(response.data);
      } catch (error) {
        console.log('Unable to fetch chatbots', error);
      } finally {
        setChatbotsLoaded(true);
      }
    }
    fetchData();
  }, []);

  const handleItemClick = React.useCallback(
    (chatbotId: string) => {
      history.push(`/app/edit-chatbot/${chatbotId}/`);
    },
    [history],
  );

  const getChatbotsList = React.useCallback(() => {
    if (!chatbotsLoaded) {
      return (
        <Flex w="100%" minH="300px" justifyContent="center" alignItems="center">
          <Spinner
            thickness="2px"
            speed="0.65s"
            emptyColor="gray.200"
            color="gray.700"
            size="xl"
          />
        </Flex>
      );
    }
    if (!chatbotsList?.length) {
      return null;
    }
    const chatbotListItems = chatbotsList?.map((chatbot) => {
      return (
        <MediaListItem
          showCustomizeMenu={false}
          name={chatbot.name}
          imageAlt={chatbot.name}
          showGetCodeMenu={false}
          imageUrl={chatbot.imageUrl}
          description={chatbot.description}
          key={chatbot.id}
          showPrimaryActionButton={false}
          actionButtonLeftIcon={null}
          isPrimaryButtonLoading={false}
          onPrimaryActionButtonClick={() => {}}
          onMenuItemClick={() => handleItemClick(chatbot.id)}
          showMenu={false}
        ></MediaListItem>
      );
    });
    return (
      <SimpleGrid columns={[1, 1, 1, 2]} spacing="6">
        {chatbotListItems}
      </SimpleGrid>
    );
  }, [chatbotsList, chatbotsLoaded, handleItemClick]);

  return (
    <Box
      w="100%"
      maxW="1200px"
      p="6"
      shadow="sm"
      h="100%"
      position="relative"
      overflow="auto"
    >
      <VStack w="100%" spacing="10">
        <Flex shrink={0} w="100%" justifyContent="space-between">
          <Heading fontSize="30">Shared Projects</Heading>
        </Flex>
        <Box width="100%">{getChatbotsList()}</Box>
      </VStack>
    </Box>
  );
};
