import * as React from 'react';

import { useHistory } from 'react-router-dom';
import { Flex, useToast } from '@chakra-ui/react';
import {
  Knowledgebase,
  ChatBotCustomizeData,
} from '../../../../types/knowledgebase.type';
import {
  customizeWidget,
  updatePrompt,
  updateDefaultAnswer,
  updateModel,
} from '../../../../services/knowledgebaseService';
import { ChatBotsCustomize } from '../../../ChatBotsCustomize/ChatBotsCustomize';
import { chatWidgetDefaultValues } from '../../../../utils/commonUtils';

interface CustomizeProps {
  chatBot: Knowledgebase;
}

export const Customize = ({ chatBot }: CustomizeProps) => {
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const history = useHistory();
  const toast = useToast();

  const getDefaultCustomizationValues = React.useCallback(() => {
    if (!chatBot.id) return;
    return {
      backgroundColor: chatBot.chatWidgetData?.backgroundColor,
      fontColor: chatBot.chatWidgetData?.fontColor,
      placement: chatBot.chatWidgetData?.placement,
      borderRadius: chatBot.chatWidgetData?.borderRadius,
      heading: chatBot.chatWidgetData?.heading,
      description: chatBot.chatWidgetData?.description,
      offlineMessage: chatBot.chatWidgetData?.offlineMessage,
      showReadMore:
        chatBot.chatWidgetData?.showReadMore === undefined
          ? chatWidgetDefaultValues.showReadMore
          : chatBot.chatWidgetData?.showReadMore,
      showAsPopup:
        chatBot.chatWidgetData?.showAsPopup === undefined
          ? chatWidgetDefaultValues.showAsPopup
          : chatBot.chatWidgetData?.showAsPopup,
      popupDelay:
        chatBot.chatWidgetData?.popupDelay ||
        chatWidgetDefaultValues.popupDelay,
      collectEmailText:
        chatBot.chatWidgetData?.collectEmailText ||
        chatWidgetDefaultValues.collectEmailText,
      collectEmail: chatBot.chatWidgetData?.collectEmail,
      customCSS:
        chatBot.chatWidgetData?.customCSS || chatWidgetDefaultValues.customCSS,
      questionExamples:
        chatBot.chatWidgetData?.questionExamples ||
        chatWidgetDefaultValues.questionExamples,
      welcomeMessages: chatBot.chatWidgetData?.welcomeMessage
        ? [chatBot.chatWidgetData?.welcomeMessage]
        : chatBot.chatWidgetData?.welcomeMessages ||
          chatWidgetDefaultValues.welcomeMessages,
      prompt: chatBot.prompt || chatWidgetDefaultValues.prompt,
      defaultAnswer:
        chatBot.defaultAnswer || chatWidgetDefaultValues.defaultAnswer,
      launcherIcon:
        chatBot.chatWidgetData?.launcherIcon ||
        chatWidgetDefaultValues.launcherIcon,
      chatInputPlaceholderText:
        chatBot.chatWidgetData?.chatInputPlaceholderText ||
        chatWidgetDefaultValues.chatInputPlaceholderText,
      assistantTabHeader:
        chatBot.chatWidgetData?.assistantTabHeader ||
        chatWidgetDefaultValues.assistantTabHeader,
      offlineMsgTabHeader:
        chatBot.chatWidgetData?.offlineMsgTabHeader ||
        chatWidgetDefaultValues.offlineMsgTabHeader,
      readMoreText:
        chatBot.chatWidgetData?.readMoreText ||
        chatWidgetDefaultValues.readMoreText,
      offlineMsgHeading:
        chatBot.chatWidgetData?.offlineMsgHeading ||
        chatWidgetDefaultValues.offlineMsgHeading,
      offlineMsgDescription:
        chatBot.chatWidgetData?.offlineMsgDescription ||
        chatWidgetDefaultValues.offlineMsgDescription,
      nameFieldLabel:
        chatBot.chatWidgetData?.nameFieldLabel ||
        chatWidgetDefaultValues.nameFieldLabel,
      nameFieldPlaceholder:
        chatBot.chatWidgetData?.nameFieldPlaceholder ||
        chatWidgetDefaultValues.nameFieldPlaceholder,
      emailFieldLabel:
        chatBot.chatWidgetData?.emailFieldLabel ||
        chatWidgetDefaultValues.emailFieldLabel,
      emailFieldPlaceholder:
        chatBot.chatWidgetData?.emailFieldPlaceholder ||
        chatWidgetDefaultValues.emailFieldPlaceholder,
      msgFieldLabel:
        chatBot.chatWidgetData?.msgFieldLabel ||
        chatWidgetDefaultValues.msgFieldLabel,
      msgFieldPlaceholder:
        chatBot.chatWidgetData?.msgFieldPlaceholder ||
        chatWidgetDefaultValues.msgFieldPlaceholder,
      requiredFieldMsg:
        chatBot.chatWidgetData?.requiredFieldMsg ||
        chatWidgetDefaultValues.requiredFieldMsg,
      invalidEmailMsg:
        chatBot.chatWidgetData?.invalidEmailMsg ||
        chatWidgetDefaultValues.invalidEmailMsg,
      formSubmitBtnLabel:
        chatBot.chatWidgetData?.formSubmitBtnLabel ||
        chatWidgetDefaultValues.formSubmitBtnLabel,
      model: chatBot.model || chatWidgetDefaultValues.model,
    };
  }, [chatBot]);

  return (
    <Flex h="100%" direction="column">
      {chatBot.id && (
        <ChatBotsCustomize
          onBackClick={() => {
            history.push('/app/chat-bots/');
          }}
          isSubmitting={isSubmitting}
          primaryButtonLabel="Update widget style"
          defaultCustomizationValues={getDefaultCustomizationValues()}
          onNextClick={async (formData: ChatBotCustomizeData) => {
            try {
              setIsSubmitting(true);
              customizeWidget(chatBot.id, formData);
              updatePrompt(chatBot.id, formData.prompt || '');
              updateDefaultAnswer(chatBot.id, formData.defaultAnswer || '');
              updateModel(chatBot.id, formData.model || '');
              toast({
                title: `Chatbot customizations have been updated successfully`,
                status: 'success',
                isClosable: true,
              });
            } catch (error) {
              toast({
                title: `Oops! Something went wrong`,
                status: 'error',
                isClosable: true,
              });
            } finally {
              setIsSubmitting(false);
            }
          }}
        />
      )}
    </Flex>
  );
};
